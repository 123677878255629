<template>
  <main>
    <v-card flat>
      <v-card-title>
        Publishers

        <v-btn icon class="ml-3" @click="getPublishers" :disabled="publishers.loading"><v-icon>{{ icons.mdiReload }}</v-icon></v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="tableColumns"
          :footer-props="{
            'items-per-page-options': [10, 10],
            'disable-items-per-page': true,
            'disable-pagination': publishers.loading
          }"
          :items="publishers.list"
          :options.sync="publishers.options"
          :page="publishers.meta.page"
          :server-items-length="publishers.meta.total || 0"
          :loading="publishers.loading"
          @pagination="changePagination($event)"
          :no-data-text="'No data available'"
          :loading-text="'Loading, pls wait'"
          class="text-no-wrap"
        >
          <template #[`item.daysActive`]="{item}">
            {{ getActiveDays(item) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </main>
</template>

<script>
import { mdiInformation, mdiReload, mdiFileEdit, mdiShieldLock } from '@mdi/js'
import moment from 'moment'

export default {
  data: () => ({
    icons: { mdiInformation, mdiReload, mdiFileEdit, mdiShieldLock },
    publishers: {
      loading: false,
      docs: [],
      meta: {},
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
    },
  }),
  computed: {
    tableColumns() {
      return [
        { text: 'AFFILIATE WALLET', value: 'wallet', align: 'start', sortable: false },
        { text: 'OFFER', value: 'offer.name', sortable: false },
        { text: 'SALES', value: 'sales', sortable: false },
        { text: 'DAYS ACTIVE', value: 'daysActive', sortable: false },
      ]
    },
  },
  methods: {
    getPublishers(query) {
      this.publishers.loading = true

      const params = {
        limit: this.publishers.options.itemsPerPage,
        query: JSON.stringify(query),
        sort: { createdAt: 'desc' },
        ...params,
      }

      this.$http
        .get('/advertiser/publishers', { params })
        .then(({ data }) => {
          const { meta, docs } = data

          this.publishers.meta = meta
          this.publishers.list = docs
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$nextTick(() => {
            this.publishers.loading = false
          })
        })
    },

    changePagination(pagination) {
      this.getPublishers({
        page: pagination.page,
      })
    },

    getActiveDays(item) {
      const now = moment()

      return now.diff(moment(item.firstCreated), 'days') + 1
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
